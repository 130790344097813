<template>
  <div id="kpis-container">
    <h2><i class="fad fa-fw fa-funnel-dollar"></i> KPI Tool</h2>
    <p class="lead">
      Define your KPIs from Google Analytics and map them to your
      <router-link to="/journeys">Customer Journeys</router-link>
      ,
      <router-link to="/channels">Traffic Channels</router-link>
      and see how your website performs in the
      <router-link to="/calculations">Success Dashboard</router-link>
      .
    </p>

    <ComingSoonBanner></ComingSoonBanner>

    <!--<p>Todo:</p>
    <ul>
      <li>Build Calculations</li>
      <li>Build Charts</li>
      <li>Connect to <router-link to="/experiments">Experiments</router-link></li>
      <li>Connect to <router-link to="/journeys">Customer Journeys</router-link></li>
      <li>Connect to <router-link to="/channels">Traffic Channels</router-link></li>
    </ul>-->
  </div>
</template>

<script>
// @ is an alias to /src

import ComingSoonBanner from "@/components/ComingSoonBanner";

export default {
  name: 'Kpis',
  components: {
    ComingSoonBanner
  }
}
</script>
